<template>
  <LayoutBase>
    <Box flex direction="col" spaceY="5" grow="grow" shrink="shrink" minWidth="0">
      <Box flex col spaceY="4">
        <Box flex alignItems="center" spaceX="4" spaceY="2" minHeight="36px">
          <PageTitle>
            <template v-if="$slots.title" #title>
              <slot name="title" />
            </template>
            <template v-if="$slots.titlePlaceholder" #titlePlaceholder>
              <slot name="titlePlaceholder" />
            </template>
          </PageTitle>

          <slot name="info" />

          <Box flex spaceX="2" spaceY="2" justify="end" grow="grow" shrink="shrink-0">
            <slot name="actions" />
          </Box>
        </Box>
        <Box v-if="$slots.breadcrumbs">
          <slot name="breadcrumbs" />
        </Box>
      </Box>

      <slot />
    </Box>
  </LayoutBase>
</template>

<script setup lang="ts">
import { Box } from '@lasso/luikit'
import { LayoutBase } from '@activation-objects/components/layouts/LayoutBase'
import { PageTitle } from '@activation-objects/components/shared/PageTitle'

defineProps<{
  actionsKey?: PropertyKey | null
  titleKey?: PropertyKey | null
}>()
</script>
