<template>
  <ViewUniversalPixelDetails :pixelId="pixelId" />
  <RouterView v-slot="{ Component }">
    <ModalWrapper variant="drawer">
      <component :is="Component" />
    </ModalWrapper>
  </RouterView>
</template>

<script setup lang="ts">
import { ModalWrapper } from '@lasso/luikit'
import { ViewUniversalPixelDetails } from '@activation-objects/components/ViewDigitalEvents/ViewUniversalPixelDetails'

defineProps<{
  pixelId: number
}>()
</script>
