<template>
  <ViewUniversalPixelAudienceUpdate :pixelId="pixelId" :audienceId="audienceId" />
</template>

<script setup lang="ts">
import { ViewUniversalPixelAudienceUpdate } from '@activation-objects/components/ViewDigitalEvents/ViewUniversalPixelAudienceUpdate'

defineProps<{
  pixelId: number
  audienceId: number
}>()
</script>
