<template>
  <Skeleton height="60px" />

  <Box flex col space="2">
    <Skeleton height="168px" />
    <Skeleton width="96px" height="18px" />
  </Box>

  <Box flex spaceX="2" justify="between" alignItems="end" grow="grow" wrap="wrap">
    <Skeleton width="70px" height="36px" />
    <Skeleton width="70px" height="36px" />
  </Box>
</template>

<script setup lang="ts">
import { Box, Skeleton } from '@lasso/luikit'
</script>
