<template>
  <Box class="layout-base">
    <slot />
  </Box>
</template>

<script setup lang="ts">
import { Box } from '@lasso/luikit'
</script>

<style scoped>
.layout-base {
  @apply flex flex-col gap-4;
  min-height: calc(100vh - 96px);
}
</style>
