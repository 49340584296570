<template>
  <Card collapse opened withDivider>
    <template #headerTitle>
      <Box flex space="1">
        <Typography variant="lato-h6" color="textPrimary">
          Site Events
        </Typography>
        <Tooltip placement="top" content="Site events can be used in Ad Groups for audiences, conversion tracking, or optimization." withIcon />
      </Box>
    </template>
    <Box flex col space="2">
      <Box v-if="!visible.empty" flex justify="between" space="2" mb="4" wrap="wrap">
        <Box width="220px">
          <InputText
            v-model="searchQueryDebounced"
            placeholder="Search"
            name="search"
            icon="search"
          />
        </Box>
      </Box>

      <ErrorState v-if="visible.error" :error="error" @retry="retry()" />
      <EmptyState v-else-if="visible.empty" p="0" message="There are no site events." />
      <Box v-else>
        <Table
          v-model:sorting="sorting"
          :data="audiences"
          :fields="allColumns"
          :loading="loading"
          :skeleton="{ rows: pageSize }"
          :defaultSorting="defaultSorting"
          hoverable
        >
          <template #audienceConversionId="{ value }">
            <Box alignItems="center">
              <Chip variant="outlined" :label="value" />
            </Box>
          </template>

          <template #name="{ value, row, hovered }">
            <TableCellName
              :hovered="hovered"
              :href="router.resolve({
                name: RouteName.UniversalPixelAudienceUpdate,
                params: { pixelId, audienceId: row.id },
                query: route.query,
              }).fullPath"
              clickable
              dropdownWidth="150px"
            >
              {{ value }}
              <template v-if="permissions.digitalEvents.canManage" #dropdown="{ hide }">
                <ListItemButton v-if="permissions.digitalEvents.canManage" @click="onDelete(row, hide)">
                  Delete
                </ListItemButton>
              </template>
            </TableCellName>
          </template>
        </Table>

        <TablePagination
          v-if="visible.pagination"
          v-bind="{ loading, pagesTotal, total, pageSizes }"
          v-model:pageSize="pageSize"
          v-model="pageNumber"
        />
      </Box>
    </Box>
  </Card>

  <ModalConfirm v-bind="modalProps" />
</template>

<script setup lang="ts">
import {
  Box, Card,
  Chip, EmptyState,
  ErrorState,
  InputText,
  ListItemButton, ModalConfirm,
  Table,
  TableCellName, TablePagination,
  Tooltip, Typography,
  useModalConfirm,
  useTableVisibility,
} from '@lasso/luikit'
import { reversedDebouncedRef } from '@lasso/shared/utils'
import { useRoute, useRouter } from 'vue-router'
import { RouteName } from '@activation-objects/router'
import { useApiManual, useToast } from '@lasso/shared/hooks'
import { useActivationApi } from '@lasso/api-activation/activation'
import { useAccessControl } from '@activation-objects/hooks/useAccessControl'

import { allColumns } from './consts'
import { useAudiencesApi } from './useAudiencesApi'
import { UniversalPixelAudienceRow } from './types'

const { pixelId } = defineProps<{
  pixelId: number
}>()

const { permissions } = useAccessControl()

const router = useRouter()
const route = useRoute()
const toast = useToast()

const {
  audiences,
  loading,
  error,
  retry,

  pageNumber,
  pageSize,
  pageSizes,
  total,
  pagesTotal,
  sorting,
  defaultSorting,
  searchQuery,
} = useAudiencesApi({
  pixelId: () => pixelId,
})

const searchQueryDebounced = reversedDebouncedRef(searchQuery, 300)

const visible = useTableVisibility({
  pageNumber,
  pagesTotal,
  error,
  loading,
  filtersApplied: () => Boolean(searchQuery.value),
  paginationOnFirstPage: true,
})

const { modalProps, confirmCritical } = useModalConfirm()

const api = useActivationApi()

const { requestThrows: deleteAudience } = useApiManual(api.deleteUniversalPixelAudience, {
  clearCacheFor: [api.getUniversalPixelAudiences, api.getUniversalPixelAudience],
})

const onDelete = async (row: UniversalPixelAudienceRow, hide: () => void) => {
  hide()
  try {
    const deleted = await confirmCritical({
      title: 'Delete this event?',
      subtitle: 'This action can\'t be undone.',
      onConfirm: async () => deleteAudience(row.id),
      confirmText: 'Delete',
    })

    if (deleted) {
      toast.success('Successfully deleted site event')
    }
  }
  catch (error) {
    toast.error('Failed to delete site event', error)
  }
}
</script>
